// extracted by mini-css-extract-plugin
export var authorButton = "style-module--author-button--lbMFs";
export var authorComics = "style-module--author-comics--8rHMa";
export var authorCover = "style-module--author-cover--i0McN";
export var authorCoverWrapper = "style-module--author-cover-wrapper--FdsLB";
export var authorDetail = "style-module--author-detail--2IcuW";
export var authorInfo = "style-module--author-info--NcYtn";
export var authorJob = "style-module--author-job--RsS-q";
export var authorLink = "style-module--author-link--SFIvU";
export var authorSubtitle = "style-module--author-subtitle---Isxc";
export var authorTech = "style-module--author-tech--9EmA1";
export var authorTechTitle = "style-module--author-tech-title--867jY";
export var authorTitle = "style-module--author-title--CXA8z";
export var authorTop = "style-module--author-top--BqjeY";